import React, {
  ElementType,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import cn from "clsx";
import {
  Checkmark,
  CheckmarkCircle,
  Information,
  XMarkNormal,
} from "@/components/icons";
import { useDebounce } from "@/hooks/useDebounce";
import PhoneMask from "@/utils/Masks/PhoneMask";
import useIsFirstRender from "@/hooks/useIsFirstRender";
type Variants = "solid" | "form";
type Types =
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "month"
  | "password"
  | "number"
  | "search"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week"
  | "year";
type InputModes =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  label?: string;
  hint?: string;
  error?: string;
  variant?: Variants;
  type?: Types;
  placeholder?: string;
  onClear?: () => void;
  value?: string | number;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (value: any) => void;
  onDelayChange?: (value: any) => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
  isRequired?: boolean;
  name?: string;
  id?: string;
  autoComplete?: string;
  delay?: number;
  onFocus?: (value: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (value: any) => void;
  onMouseDown?: (value: React.MouseEvent<HTMLInputElement>) => void;
  onMouseUp?: (value: React.MouseEvent<HTMLInputElement>) => void;
  onMouseEnter?: (value: React.MouseEvent<HTMLInputElement>) => void;
  onMouseLeave?: (value: React.MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  valid?: boolean;
  inputMode?: InputModes;
  className?: string;
  inputClassName?: string;
  outherClassName?: string;
  outherRef?: any;
}

const Input = (props: InputProps, ref: any) => {
  const [isFocus, setFocus] = useState(false);
  const {
    startContent,
    endContent,
    label,
    hint,
    error,
    variant = "solid",
    fullWidth = false,
    isDisabled = false,
    isReadOnly = false,
    isRequired = false,
    onChange,
    onClear,
    onValueChange,
    onDelayChange,
    placeholder,
    name,
    id,
    autoComplete,
    type = "text",
    onFocus,
    onBlur,
    onMouseDown,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
    onPaste,
    delay = 0,
    valid,
    inputMode = "text",
    className = "",
    defaultValue,
    inputClassName = "",
    outherClassName = "",
    outherRef,
    ...otherProps
  } = props;
  const isFirstRender = useIsFirstRender();
  const [value, setValue] = useState(props.value || "");

  const debouncedValue = useDebounce(value, delay);

  useEffect(() => {
    if (!isFirstRender) {
      onDelayChange && onDelayChange(debouncedValue);
    }
  }, [debouncedValue]);

  const handleOnChange = (e: any) => {
    setValue(e.target.value);
    onValueChange && onValueChange(e.target.value);
    onChange && onChange(e);
  };
  const handleOnEmpty = (e: any) => {
    setValue("");
    onClear && onClear();
  };

  const handleOnBlur = (e: any) => {
    onBlur && onBlur(e);
  };

  return (
    <div className={`flex flex-col gap-2 ${outherClassName}`}>
      {(label || hint) && (
        <div className="flex flex-col justify-start items-start">
          {label && (
            <label
              className="text-heading6 font-bold text-extended_neutral-N600"
              htmlFor={id}
            >
              {label}
            </label>
          )}
          {hint && (
            <p className="text-paragraph font-normal text-extended_neutral-N400">
              {hint}
            </p>
          )}
        </div>
      )}
      <div
        ref={outherRef}
        className={cn(
          "group rounded-page ring-1 ring-extended_neutral-N100 hover:ring-extended_neutral-N200 bg-white gap-2 flex items-center transition-all duration-500 has-[:focus]:ring-extended_neutral-N300 has-[:invalid]:ring-extended_red-R400 has-[:invalid]:text-extended_red-R400  has-[:disabled]:!bg-extended_neutral-N100 has-[:disabled]:!ring-extended_neutral-N100 has-[:disabled]:!text-extended_neutral-N200",
          { "p-2 text-paragraph": variant == "solid" },
          { "p-2 text-paragraph": variant == "form" },
          { "ring-extended_red-R400": valid == false },
          className
        )}
      >
        {startContent && startContent}
        <input
          type={type}
          className={`appearance-none bg-transparent h-5 flex-1 border-none outline-none placeholder:text-extended_neutral-N200  focus:placeholder:text-extended_neutral-N600 invalid:placeholder:text-extended_red-R400 disabled:placeholder:!text-extended_neutral-N200 disabled:!text-extended_neutral-N200 autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] text-paragraphMd placeholder:!text-paragraphMd ${inputClassName}`}
          disabled={isDisabled}
          readOnly={isReadOnly}
          value={value}
          onChange={handleOnChange}
          required={isRequired}
          placeholder={placeholder}
          name={name}
          inputMode={inputMode}
          id={id}
          autoComplete={autoComplete}
          aria-autocomplete="none"
          onFocus={onFocus}
          onBlur={handleOnBlur}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
          ref={ref}
          {...otherProps}
        />
        {endContent && endContent}
        {valid != undefined && !valid && (
          <Information className="text-extended_red-R400" />
        )}
        {value && onClear && (
          <button
            type="button"
            onClick={handleOnEmpty}
            className="rounded-full bg-extended_neutral-N200 p-[2px] text-extended_neutral-N100 transition-all duration-500 hover:scale-[0.97] hover:bg-extended_neutral-N300"
          >
            <XMarkNormal />
          </button>
        )}
      </div>
      {error && (
        <small className="text-smallMd text-extended_red-R400">{error}</small>
      )}
    </div>
  );
};

export default React.forwardRef(Input);
