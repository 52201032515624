"use client";

import React, { useEffect, useRef, useState } from "react";
import cn, { clsx } from "clsx";
import { motion } from "framer-motion";

type Sizes = "large" | "medium" | "small";
type Colors = "default" | "primary" | "success" | "danger";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  sizes?: Sizes;
  color?: Colors;
  className?: string;
  fullWidth?: boolean;
  isDisabled?: boolean;
  label?: string | React.ReactNode;
  hint?: string;
  id?: string;
  checked?: boolean;
  setChecked?: (isChecked: boolean) => void;
  onChange?: (e: any) => void;
  outherClassName?: string;
  inputClassName?: string;
}

const Checkbox = (props: Props, ref: any) => {
  const {
    sizes = "small",
    color = "default",
    fullWidth,
    isDisabled,
    hint,
    label,
    id,
    checked = false,
    name = "",
    setChecked,
    onChange,
    inputClassName = "",
    outherClassName = "",
    ...otherProps
  } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const tickVariants = {
    checked: {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 0.2,
        delay: 0.2,
      },
    },
    unchecked: {
      pathLength: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  const toggleCheckbox = (e: any) => {
    setIsChecked(!checked);
    setChecked && setChecked(!checked);
    onChange && onChange(e);
  };

  return (
    <label
      className={clsx(
        "flex items-center justify-start gap-2 select-none",
        outherClassName
      )}
    >
      <div className="relative flex items-center">
        <input
          type="checkbox"
          checked={checked}
          onChange={toggleCheckbox}
          disabled={isDisabled}
          id={id}
          ref={ref}
          name={name}
          className={cn(
            `appearance-none transition-all duration-500 ring-2 flex justify-center items-center disabled:bg-neutral-N100 disabled:!ring-neutral-N100 peer  `,
            {
              "size-6 rounded-lg bg-[length:16px_10px]": sizes == "large",
            },
            {
              "size-5 rounded-md bg-[length:12px_8px]": sizes == "medium",
            },
            {
              "size-4 rounded-[4px] bg-[length:8px_8px]": sizes == "small",
            },
            {
              "bg-white ring-extended_neutral-N100 checked:bg-extended_neutral-N600 checked:ring-extended_neutral-N600":
                color == "default",
            },
            {
              "bg-white ring-extended_orange-B50 checked:bg-extended_orange-B600 checked:ring-extended_orange-N600":
                color == "primary",
            },
            {
              "bg-white ring-extended_green-G50 checked:bg-extended_green-G200 checked:ring-extended_green-G200":
                color == "success",
            },
            {
              "bg-white ring-extended_red-R50 checked:bg-extended_red-R400 checked:ring-extended_red-R400":
                color == "danger",
            },
            inputClassName
          )}
          {...otherProps}
        />
        <div
          className={clsx("pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-extended_neutral-N100 peer-disabled:text-extended_neutral-N200"
            , {"!text-extended_neutral-N200"  : isDisabled}
          )}
        >
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="3"
            stroke="currentColor"
            className={cn(
              {
                "size-5": sizes == "large",
              },
              {
                "size-4": sizes == "medium",
              },
              {
                "size-3": sizes == "small",
              }
            )}
            initial={false}
            animate={checked ? "checked" : "unchecked"}
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
              variants={tickVariants}
            />
          </motion.svg>
        </div>
      </div>

      <div
        className={`flex flex-col justify-start gap-[2px] ${
          isDisabled ? "text-neutral-200" : ""
        }`}
      >
        {label && (
          <span
            className={cn(
              "whitespace-normal",
              { "text-heading6 leading-none": sizes === "small" },
              { "text-heading6Md leading-none": sizes === "medium" },
              { "text-heading6Lg leading-none": sizes === "large" }
            )}
          >
            {label}
          </span>
        )}
        {hint && (
          <small
            className={cn(
              "text-neutral-N300",
              { "text-small leading-none": sizes === "small" },
              { "text-smallMd leading-none": sizes === "medium" },
              { "text-smallLg leading-none": sizes === "large" }
            )}
          >
            {hint}
          </small>
        )}
      </div>
    </label>
  );
};

export default React.forwardRef(Checkbox);
