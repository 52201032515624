export const PasswordMatcher = [
  {
    matcher: /[A-Z]/,
    message: "En az bir büyük harf",
  },
  {
    matcher: /[a-z]/,
    message: "En az bir küçük harf",
  },
  {
    matcher: /[0-9]/,
    message: "En az bir rakam",
  },
  {
    matcher: /[^A-Za-z0-9]/,
    message: "En az bir özel karakter",
  },
  {
    matcher: /^.{8,32}$/,
    message: "8-32 karakter",
  },
];
