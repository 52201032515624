

function Logo({
                       size = 16,
                       className = "",
                        color = "normal",
                       ...props
                   }: {
    size?: number,
    className?: string,
    color?: "white" | "normal"
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="37" viewBox="0 0 150 37" fill="none">
            <g clipPath="url(#clip0_42_13941)">
                <path
                    d="M18.3777 36.7554C28.5274 36.7554 36.7554 28.5274 36.7554 18.3777C36.7554 8.22798 28.5274 0 18.3777 0C8.22797 0 0 8.22798 0 18.3777C0 28.5274 8.22797 36.7554 18.3777 36.7554Z"
                    fill="#F05A29"/>
                <path
                    d="M19.847 18.2586L24.0597 13.3819C24.2699 13.1381 24.5304 13.1998 24.6313 13.2376C24.7546 13.2838 24.9591 13.4043 24.9591 13.7027V22.5357L27.0255 24.2645V13.7027C27.0255 12.6226 26.3685 11.6783 25.3514 11.3001C24.3287 10.919 23.2065 11.2062 22.4948 12.03L18.2598 16.9319L19.8456 18.2586H19.847Z"
                    fill="white"/>
                <path
                    d="M16.9104 18.4968L12.6963 23.3735C12.4861 23.6173 12.2256 23.5556 12.1247 23.5178C12.0014 23.4716 11.7969 23.3511 11.7969 23.0527V14.2197L9.73047 12.4909V23.0527C9.73047 24.1328 10.3875 25.0771 11.4046 25.4553C11.7058 25.5674 12.014 25.6221 12.318 25.6221C13.0507 25.6221 13.7582 25.3082 14.2612 24.7254L18.4962 19.8235L16.9104 18.4968Z"
                    fill="white"/>
                <path
                    d="M57.7655 11.0801C58.0597 11.3827 58.2082 11.7526 58.2082 12.1911V24.5881C58.2082 25.0266 58.0639 25.3923 57.7781 25.6879C57.4909 25.9821 57.135 26.1292 56.7133 26.1292C56.3813 26.1292 56.1095 26.0535 55.898 25.9022C55.6864 25.7509 55.4525 25.517 55.1947 25.1989L48.5318 16.6321V24.5643C48.5318 25.0028 48.3847 25.3727 48.0891 25.6753C47.7949 25.9779 47.4348 26.1292 47.0131 26.1292C46.5915 26.1292 46.209 25.9779 45.9134 25.6753C45.6192 25.3727 45.4707 25.0028 45.4707 24.5643V12.1673C45.4707 11.7288 45.6136 11.3631 45.9008 11.0675C46.188 10.7733 46.5424 10.6262 46.9655 10.6262C47.313 10.6262 47.5889 10.7019 47.7935 10.8532C47.998 11.0045 48.2432 11.2609 48.5304 11.6237L55.1709 20.1233L55.1485 12.1911C55.1485 11.7526 55.2956 11.3827 55.5912 11.0801C55.8854 10.7775 56.2524 10.6262 56.6909 10.6262C57.1294 10.6262 57.4727 10.7775 57.7669 11.0801H57.7655Z"
                    fill={`${color == "white" ? "white" : "#151636"}`}/>
                <path
                    d="M72.7252 11.6573C73.9413 12.3452 74.9051 13.2824 75.6154 14.4676C76.3257 15.6542 76.6801 16.9571 76.6801 18.3777C76.6801 19.7983 76.3243 21.1011 75.6154 22.2878C74.9051 23.4744 73.9413 24.4102 72.7252 25.0981C71.5092 25.7859 70.1671 26.1292 68.7017 26.1292C67.2363 26.1292 65.8984 25.7859 64.6894 25.0981C63.4804 24.4102 62.5249 23.4772 61.823 22.299C61.1198 21.1208 60.7695 19.8137 60.7695 18.3777C60.7695 16.9417 61.1212 15.636 61.823 14.4564C62.5263 13.2782 63.4804 12.3452 64.6894 11.6573C65.8984 10.9695 67.2349 10.6262 68.7017 10.6262C70.1685 10.6262 71.5078 10.9709 72.7252 11.6573ZM66.243 14.0824C65.5103 14.4985 64.9402 15.0799 64.5325 15.828C64.1248 16.5761 63.9203 17.4264 63.9203 18.3777C63.9203 19.3289 64.1248 20.1793 64.5325 20.9274C64.9402 21.6755 65.5103 22.2569 66.243 22.673C66.9757 23.0891 67.8023 23.2964 68.7255 23.2964C69.6488 23.2964 70.4711 23.0849 71.1954 22.6618C71.9211 22.2387 72.4913 21.6531 72.906 20.905C73.3221 20.1569 73.5294 19.3149 73.5294 18.3777C73.5294 17.4405 73.3221 16.5985 72.906 15.8504C72.4899 15.1023 71.9197 14.5167 71.1954 14.0936C70.4697 13.6705 69.646 13.4589 68.7255 13.4589C67.8051 13.4589 66.9757 13.6663 66.243 14.0824Z"
                    fill={`${color == "white" ? "white" : "#151636"}`}/>
                <path
                    d="M90.8886 11.0577C91.1758 11.3449 91.3187 11.6923 91.3187 12.1C91.3187 12.418 91.2585 12.6898 91.138 12.9168L86.0385 25.0883C85.7513 25.7831 85.268 26.1306 84.5885 26.1306C83.9091 26.1306 83.4103 25.7831 83.1386 25.0883L78.0167 12.9168C77.8962 12.645 77.8359 12.3732 77.8359 12.1C77.8359 11.6923 77.983 11.3449 78.2772 11.0577C78.5714 10.7705 78.9301 10.6276 79.3532 10.6276C80.0186 10.6276 80.4936 10.9905 80.7807 11.7162L84.5885 21.0773L88.3739 11.7162C88.6765 10.9905 89.1599 10.6276 89.8239 10.6276C90.247 10.6276 90.6014 10.7719 90.8886 11.0577Z"
                    fill={`${color == "white" ? "white" : "#151636"}`}/>
                <path
                    d="M104.512 24.6568C104.512 25.0644 104.368 25.4119 104.082 25.6991C103.795 25.9863 103.439 26.1292 103.017 26.1292C102.352 26.1292 101.868 25.7663 101.567 25.042L100.933 23.501H94.6087L93.9741 25.042C93.6869 25.7677 93.2106 26.1292 92.5465 26.1292C92.1234 26.1292 91.7648 25.9849 91.4706 25.6991C91.1764 25.4119 91.0293 25.0644 91.0293 24.6568C91.0293 24.4452 91.0895 24.1734 91.21 23.8414L96.3319 11.6699C96.6037 10.9751 97.087 10.6276 97.7819 10.6276C98.4768 10.6276 98.9601 10.9751 99.2319 11.6699L104.331 23.8414C104.452 24.1286 104.512 24.4004 104.512 24.6568ZM95.6048 20.9849H99.9113L97.7805 15.1373L95.6048 20.9849Z"
                    fill={`${color == "white" ? "white" : "#151636"}`}/>
                <path
                    d="M116.15 11.8282C117.381 12.4489 118.337 13.3203 119.016 14.4466C119.696 15.573 120.036 16.8689 120.036 18.3343C120.036 19.7997 119.693 21.1026 119.005 22.2443C118.317 23.3847 117.355 24.2729 116.115 24.9075C114.876 25.5422 113.47 25.8588 111.899 25.8588H108.545C108.091 25.8588 107.717 25.7117 107.423 25.4175C107.129 25.1233 106.98 24.7492 106.98 24.2953V12.4643C106.98 12.0104 107.128 11.6363 107.423 11.3421C107.717 11.0479 108.091 10.8994 108.545 10.8994H111.899C113.501 10.8994 114.917 11.209 116.148 11.8282H116.15ZM110.042 13.6635V23.0919H111.877C112.874 23.0919 113.754 22.8958 114.518 22.5021C115.28 22.1098 115.87 21.5537 116.286 20.8364C116.702 20.1191 116.909 19.2841 116.909 18.3315C116.909 16.8955 116.459 15.7593 115.56 14.9201C114.66 14.081 113.433 13.6621 111.877 13.6621H110.042V13.6635Z"
                    fill={`${color == "white" ? "white" : "#151636"}`}/>
                <path
                    d="M134.791 24.6568C134.791 25.0644 134.647 25.4119 134.361 25.6991C134.074 25.9863 133.718 26.1292 133.297 26.1292C132.631 26.1292 132.148 25.7663 131.847 25.042L131.212 23.501H124.888L124.253 25.042C123.966 25.7677 123.49 26.1292 122.826 26.1292C122.403 26.1292 122.044 25.9849 121.75 25.6991C121.456 25.4119 121.309 25.0644 121.309 24.6568C121.309 24.4452 121.369 24.1734 121.489 23.8414L126.611 11.6699C126.883 10.9751 127.366 10.6276 128.061 10.6276C128.756 10.6276 129.239 10.9751 129.511 11.6699L134.611 23.8414C134.731 24.1286 134.791 24.4004 134.791 24.6568ZM125.884 20.9849H130.191L128.06 15.1373L125.884 20.9849Z"
                    fill={`${color == "white" ? "white" : "#151636"}`}/>
                <path
                    d="M149.557 11.0801C149.851 11.3827 149.998 11.7526 149.998 12.1911V24.5881C149.998 25.0266 149.854 25.3923 149.568 25.6879C149.281 25.9821 148.927 26.1292 148.504 26.1292C148.171 26.1292 147.9 26.0535 147.688 25.9022C147.477 25.7509 147.243 25.517 146.985 25.1989L140.322 16.6321V24.5643C140.322 25.0028 140.175 25.3727 139.881 25.6753C139.586 25.9779 139.226 26.1292 138.805 26.1292C138.383 26.1292 138.001 25.9779 137.705 25.6753C137.411 25.3727 137.264 25.0028 137.264 24.5643V12.1673C137.264 11.7288 137.407 11.3631 137.694 11.0675C137.981 10.7733 138.335 10.6262 138.758 10.6262C139.106 10.6262 139.382 10.7019 139.586 10.8532C139.791 11.0045 140.036 11.2609 140.323 11.6237L146.964 20.1233L146.941 12.1911C146.941 11.7526 147.089 11.3827 147.383 11.0801C147.677 10.7775 148.044 10.6262 148.483 10.6262C148.921 10.6262 149.264 10.7775 149.558 11.0801H149.557Z"
                    fill={`${color == "white" ? "white" : "#151636"}`}/>
            </g>
            <defs>
                <clipPath id="clip0_42_13941">
                    <rect width="150" height="36.7568" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Logo;
