import React from "react";
import {
  AlarmBell,
  Certificate,
  CheckmarkCircle,
  PriceTag,
} from "@/components/icons";
import Text from "@/components/ui/Text";
type AlertTypes = "success" | "error" | "warning" | "info";
type IAlertProps = {
  type?: AlertTypes;
  size?: "small" | "medium" | "large";
  title: string;
  hint?: string;
};

const typeClassName = (type: AlertTypes) => {
  switch (type) {
    case "info":
      return "bg-extended_neutral-N100 text-extended_neutral-N700";
    case "success":
      return "bg-extended_green-G50 text-extended_green-G500";
    case "warning":
      return "bg-extended_yellow-Y100 text-extended_yellow-Y600";
    case "error":
      return "bg-extended_red-R100 text-extended_red-R400";
    default:
      return "bg-extended_neutral-N100 text-extended_neutral-N700";
  }
};
const typeIconClassName = (type: AlertTypes) => {
  switch (type) {
    case "info":
      return "text-extended_neutral-N700";
    case "success":
      return "text-extended_green-G500";
    case "warning":
      return "text-extended_yellow-Y500";
    case "error":
      return "text-extended_red-R400";
    default:
      return "";
  }
};
const typeIcon = (type: AlertTypes) => {
  switch (type) {
    case "info":
      return <PriceTag size={24} className="text-extended_neutral-N600" />;
    case "success":
      return <CheckmarkCircle size={24} className="text-extended_green-G500" />;
    case "warning":
      return <AlarmBell size={24} className="text-extended_yellow-Y600" />;
    case "error":
      return <AlarmBell size={24} className="text-extended_red-R400" />;
    default:
      return "";
  }
};

const Alert = (props: IAlertProps) => {
  const { type = "info", size, title, hint } = props;
  return (
    <div
      className={`w-full flex p-4 items-center gap-3 rounded-lg ${typeClassName(
        type
      )}`}
    >
      <span
        className={`flex justify-center items-center ${typeIconClassName(
          type
        )}`}
      >
        {typeIcon(type)}
      </span>
      <div className="flex flex-col gap-1 items-start">
        <Text as="p" variant="paragraph" className="!text-paragraph">
          {title}
        </Text>
      </div>
    </div>
  );
};

export default Alert;
