"use client";
import React, { useState } from "react";
import Logo from "@/components/shareds/Logo";
import Input from "@/components/ui/Forms/Input";
import Checkbox from "@/components/ui/Forms/Checkbox";
import Link from "next/link";
import Button from "@/components/ui/Buttons/Button";
import PasswordInput from "@/components/ui/Forms/PasswordInput";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ILogin, LoginSchema } from "@/schemas/Auth/LoginSchema";
import { Login } from "@/services/Auth/Login";
import Alert from "@/components/ui/Alert/Alert";
import { motion } from "framer-motion";
import { useRouter } from "next/navigation";
import { useTransition } from "react";

type Props = {};

const LoginPageComp = (props: Props) => {
  const [isPending, startTransition] = useTransition();
  const router = useRouter();
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ILogin>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      username: "",
      password: "",
      remember_me: false,
    },
  });

  const onSubmit = async (values: ILogin) => {
    startTransition(async () => {
      Login(values).then((data) => {
        setError(data?.error);
        setSuccess(data?.success);
        if (data?.success) {
          setTimeout(() => {
            router.push("/company");
          }, 1000);
        }
      });
    });
  };
  return (
    <div className="flex flex-col gap-10 w-full items-center px-1 mobile:px-5 py-1 mobile:py-5">
      <Logo />
      {error && (
        <motion.div
          className="w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        >
          <Alert title={error} type="error" />
        </motion.div>
      )}
      {success && (
        <motion.div
          className="w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        >
          <Alert title={success} type="success" />
        </motion.div>
      )}
      <form
        className="flex flex-col items-center gap-[38px] w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-3 w-full">
          <Input
            inputMode="email"
            {...register("username")}
            placeholder="Kullanıcı adı veya e-mail hesabınızı yazınız"
            label="Kullanıcı Adı/E-Mail:"
            error={errors.username?.message}
            autoComplete="username"
          />
          <PasswordInput
            value={watch("password")}
            onChange={(e) => setValue("password", e.target.value)}
            placeholder="Şifrenizi buraya yazınız"
            label="Parola:"
            error={errors.password?.message}
            autoComplete="current-password"
          />
        </div>
        <div className="flex flex-col mobile:flex-row gap-3 justify-between items-start w-full">
          <Checkbox
            {...register("remember_me")}
            label="Beni Hatırla"
            checked={watch("remember_me")}
          />
          <Link href="/forgot-password" className="text-button underline">
            Şifrenizi mi Unuttunuz?
          </Link>
        </div>
        <Button
          loading={isPending}
          loadingContent="Giriş Yapılıyor"
          disabled={isPending}
          submit
          fullWidth
          size="large"
        >
          Giriş Yap
        </Button>
      </form>
      <div className="flex flex-col mobile:flex-row gap-[38px] justify-center items-center w-full">
        <Link href="/register" className="text-button underline">
          Henüz üye değil misiniz?
        </Link>
        <Link href="/fast-login" className="text-button underline">
          Hızlı Giriş Yap
        </Link>
      </div>
    </div>
  );
};

export default LoginPageComp;
