import React, { useState } from "react";
import Input, { InputProps } from "./Input";
import { Checkmark, Eye, EyeOff } from "@/components/icons";
import Text from "@/components/ui/Text";
import { AnimatePresence, motion } from "framer-motion";
import { PasswordMatcher } from "@/utils/passwordMatcher";
import { usePopper } from "react-popper";

interface IPasswordProps extends InputProps {
  matcher?: boolean;
}

const PasswordInput = (props: IPasswordProps, ref: any) => {
  const { endContent, onChange, matcher = false, ...otherProps } = props;
  const [isVisible, setIsVisible] = useState(true);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const [isFocus, setFocus] = useState(false);
  const [value, setValue] = useState(props.value);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 20],
        },
      },
    ],
  });
  return (
    <div className="relative" onBlur={() => setFocus(false)}>
      <Input
        type={isVisible ? "password" : "text"}
        ref={ref}
        outherRef={setReferenceElement}
        onFocus={() => setFocus(true)}
        onChange={(e) => {
          setValue(e.target.value);
          onChange && onChange(e);
        }}
        endContent={
          endContent ?? (
            <button type="button" onClick={toggleVisibility}>
              {isVisible ? <Eye /> : <EyeOff />}
            </button>
          )
        }
        autoComplete="new-password"
        {...otherProps}
      />
      <AnimatePresence>
        {isFocus && matcher && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            ref={setPopperElement}
            style={{ ...styles.popper }}
            className="absolute max-w-[210px] w-full  p-2 rounded-lg bg-white shadow flex flex-col gap-2  z-[999999]"
            {...attributes.popper}
          >
            <Text as="span" variant="h6">
              Güvenli bir şifre için
            </Text>
            <ul className="flex flex-col gap-1">
              {PasswordMatcher.map((item, index) => {
                const regex = new RegExp(item.matcher);
                return (
                  <li key={index} className="flex gap-1 items-center">
                    <Checkmark
                      className={
                        regex.test(String(value))
                          ? "text-extended_green-G500"
                          : ""
                      }
                    />
                    <Text as="p" variant="small">
                      {item.message}
                    </Text>
                  </li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default React.forwardRef(PasswordInput);
